<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex align-items-center" v-if="wallet">
                <div class="avatar-sm flex-shrink-0">
                    <span class="avatar-title bg-soft-primary text-primary rounded-2 fs-2">
                        <i class="bx bx-wallet-alt text-primary"></i>
                    </span>
                </div>
                <div class="flex-grow-1 overflow-hidden ms-3">
                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3">
                        e-Wallet</p>
                    <div class="d-flex align-items-center mb-3">
                        <h2 class="fs-4 flex-grow-1 mb-0"><span>{{ toIDr(wallet.saldo) }}</span></h2>
                    </div>
                    <p class="text-muted text-truncate mb-0"></p>
                </div>
            </div>
            <div v-else>
                <ParagraphShimmer :is-loading="true" :lines="3" />
            </div>
        </div><!-- end card body -->
    </div>
</template>

<script>
import httpService from '../../services/http-service';
import { endpoint } from '../../host';
import globalService from '../../services/global-service';
import { ParagraphShimmer } from "vue3-shimmer";

export default {
    components: {
        ParagraphShimmer,
    },

    created() {
        this.getProfile();
    },

    data() {
        return {
            investor: null,
            wallet: null,
        };
    },

    methods: {

        async getProfile() {
            let res = await httpService.get(endpoint.profile);
            this.investor = res.data.data.investor;
            this.wallet = res.data.data.wallet;
        },

        toDate(a) {
            return globalService.dateFormat(a);
        },

        toIDr(a) {
            return globalService.toIdr(a);
        }
    }
}
</script>

<style>
</style>
<template>
    <h2 class="card-title mb-3 flex-grow-1">Withdraw</h2>

    <p>Minimum saldo untuk withdraw <span class="text-success">{{ toIdr(minimal_withdraw) }}</span> Saldo anda saat ini
        <span
            :class="{ 'text-danger': withdraw.amount < minimal_withdraw, 'text-success': withdraw.amount >= minimal_withdraw }">{{
                    toIdr(saldo)
            }}</span>
    </p>

    <form @submit.prevent="submit">

        <div class="mb-3">
            <label class="form-label">Withdraw</label>
            <CurrencyInput v-model="withdraw_amount" :options="{ currency: 'IDR' }" v-on:change="witdrawAmountChange()"
                :disable="withdraw.amount < minimal_withdraw" />
            <small v-if="is_exceed" class="text-danger text-end">saldo tidak cukup atau jumlah withdraw kurang dari
                <span>{{ toIdr(minimal_withdraw) }}</span></small>
        </div>

        <div class="row">
            <div class="col-md-4">
                <div class="mb-3">
                    <label class="form-label">Biaya Transfer</label>
                    <CurrencyInput v-model="charge" :options="{ currency: 'IDR' }" :disable="true" />
                </div>
            </div>
            <div class="col-md-8">
                <div class="mb-3">
                    <label class="form-label">Total Akhir</label>
                    <CurrencyInput v-model="withdraw.amount" :options="{ currency: 'IDR' }" :disable="true" />
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">Biaya Transfer</label>
            <!-- <input v-model="charge" type="text" class="form-control" readonly /> -->
            <CurrencyInput v-model="charge" :options="{ currency: 'IDR' }" :disable="true" />
        </div>

        <div class="mb-3">
            <label class="form-label">Nama Bank</label>
            <input v-model="withdraw.bank_name" type="text" class="form-control" readonly />
        </div>

        <div class="mb-3">
            <label class="form-label">Nama Akun Bank</label>
            <input v-model="withdraw.bank_account_name" type="text" class="form-control" readonly />
        </div>

        <div class="mb-4">
            <label class="form-label">Nomor Akun Bank</label>
            <input v-model="withdraw.bank_account_number" type="number" class="form-control" readonly />
        </div>

        <div class="float-end">
            <button :disabled="load || is_exceed" type="submit" class="btn btn-primary waves-effect waves-light">
                <i class="bx font-size-16 align-middle me-2"
                    :class="{ 'bx-loader bx-spin': load, 'bx-download': !load }"></i>
                Withdraw {{ toIdr(withdraw.amount) }}
            </button>
        </div>

    </form>
</template>

<script>
import { endpoint } from "../../host";
import httpService from "../../services/http-service";
import errorService from "../../services/error-service";
import Swal from "sweetalert2";
import globalService from '../../services/global-service';
import CurrencyInput from "../../components/CurrencyInput.vue";

export default {
    name: 'WithdrawTab',
    props: {
        callback: { type: Function, required: false },
    },
    components: { CurrencyInput },
    created() {
        this.generateDataTableUrl();
        this.getProfile();
    },
    data() {
        return {
            load: false,
            saldo: 0,
            minimal_withdraw: 150000,
            is_exceed: false,
            charge: 2000,
            withdraw_amount: 0,
            withdraw: {
                amount: null,
                bank_name: null,
                bank_account_name: null,
                bank_account_number: null,
            },
            show_filter: false,
        };
    },
    methods: {
        async submit() {
            this.witdrawAmountChange();
            try {
                this.load = true;
                let res = await httpService.post(endpoint.withdraw, this.withdraw);
                this.load = false;
                if (res.status == 200) {
                    Swal.fire({
                        title: "Berhasil",
                        text: "withdraw berhasil diajukan, silahkan tunggu verefikasi admin",
                        icon: "success",
                    });
                    this.callback({ status: true });
                }
            }
            catch (error) {
                this.load = false;
                errorService.displayError(error);
            }
        },
        generateDataTableUrl() {
            let q = new URLSearchParams();
            this.dataTableUrl = endpoint.transaction + "?" + q.toString();
        },

        async getProfile() {
            let res = await httpService.get(endpoint.profile);
            let investor = res.data.data.investor;
            let wallet = res.data.data.wallet;

            this.withdraw.bank_name = investor.bank_name;
            this.withdraw.bank_account_name = investor.bank_account_name;
            this.withdraw.bank_account_number = investor.bank_account_number;

            this.saldo = wallet.saldo;
            this.withdraw_amount = this.saldo - this.charge;
            this.withdraw.amount = this.withdraw_amount + this.charge;

            this.is_exceed = this.isValidWithDrawAmount();
        },

        toIdr(a) {
            return globalService.toIdr(a);
        },

        witdrawAmountChange() {
            this.withdraw.amount = this.withdraw_amount + this.charge;
            this.is_exceed = this.isValidWithDrawAmount();
        },

        isValidWithDrawAmount() {
            if (this.withdraw.amount <= this.saldo && this.withdraw.amount >= this.minimal_withdraw) {
                return false;
            }
            return true;
        }
    },
}
</script>

<style>
</style>